import * as React from 'react';
import {Avatar, Button, Table, Tag} from 'antd';
import {useNavigate} from "react-router-dom";
import {useAppSelector} from "../../redux/hooks";
import {shallowEqual} from "react-redux";
import {useContext} from "react";
import {AuthContext} from "../../core/AuthContext";
import dayjs from "dayjs";

export const Home = () => {
    const navigate = useNavigate();
    const {userId} = useContext(AuthContext);

    const messages: any = useAppSelector(({messages}) => messages.messagesChats, shallowEqual);
    const chats = useAppSelector(({chats}) => chats.items, shallowEqual);

    const chatsItems = () => {
        if (Object.values(messages).length !== 0) {
           return  Object.values(chats).map((item: any) => {

                const statuses = [];
                let lastUpdate = '';
                let message = '-'

                if (messages[item.id]) {
                    if (messages[item.id][messages[item.id].length - 1]) {
                        const msgLast = messages[item.id][messages[item.id].length - 1];
                        message = `${msgLast.image ? 'Photo' : msgLast.text.length > 15 ? msgLast.text.substring(0, 15) + '...' : msgLast.text} (${dayjs(msgLast.created).format('DD.MM.YYYY hh:mm:ss')})`
                        if (msgLast.userId !== userId && !msgLast.isSeen) {
                            lastUpdate = msgLast.created
                            statuses.push('NEW MESSAGE')
                        } else {
                            lastUpdate = msgLast.created
                        }
                    }
                }

                if (statuses.length === 0) {
                    statuses.push('NONE')
                }


                return ({
                    key: item.id,
                    chat: item.id,
                    ID: item.expand.userId.id,
                    firstName: `${item.expand.userId.name} ${item.expand.userId.dataOnboarding.lastName} (${item.expand.userId.dataOnboarding.old} years)`,
                    statuses,
                    lastUpdate,
                    lastMessage: message
                })
            }).sort((a,b) => new Date(b.lastUpdate).getSeconds() - new Date(a.lastUpdate).getSeconds())

        } else {
            return []
        }
    }

    const items = chatsItems()
    const isLoading = Object.values(messages).length !== items.length || items.length === 0

    return (
            <Table dataSource={isLoading ? [] : items} loading={isLoading} pagination={false}>
                <Table.Column title="Chat ID" dataIndex="chat" key="chat" />
                <Table.Column title="User ID" dataIndex="ID" key="ID" />
                <Table.Column title="FirstName Lastname" dataIndex="firstName" key="firstName" />
                <Table.Column title="Last Message" dataIndex="lastMessage" key="lastMessage" />
                <Table.Column
                    width='25%'
                    title="Status"
                    dataIndex="statuses"
                    key="status"
                    render={(tags: string[]) => (
                        <>
                            {tags.map((tag) => {
                                return (
                                    <Tag color={tag === 'NONE' ? '#545454': 'green'} key={tag} >
                                        {tag.toUpperCase()}
                                    </Tag>
                                );
                            })}
                        </>
                    )}
                />
                <Table.Column
                    title="Action"
                    key="action"
                    render={(_: any, record: any) => (
                        <Button type='primary' onClick={() => navigate(`/chat/${record.key}/${record.ID}`)}>
                            Go Chat Client
                        </Button>
                    )}
                />
            </Table>
    );
}

