import * as React from 'react';
import {
    MenuFoldOutlined,
    MenuUnfoldOutlined,
    UserOutlined
} from '@ant-design/icons';
import {Layout, Menu, Button, theme} from 'antd';
import {Route, Routes, useNavigate} from "react-router-dom";
import {Home} from "../pages/Home/Home";
import Sider from "antd/es/layout/Sider";
import {useState} from "react";
import {Content, Header} from "antd/es/layout/layout";

import logoImg from '../assets/logo.svg';
import styled from "styled-components";
import {Chat} from "../pages/Chat/Chat";

export const Template = () => {
    const [collapsed, setCollapsed] = useState(false);
    const {
        token: { colorBgContainer, borderRadiusLG },
    } = theme.useToken();
   const navigate = useNavigate();



    return (
    <Layout style={{height:"100vh"}}>
        <Sider trigger={null} collapsible collapsed={collapsed}>
            <Center>
            <Logo src={logoImg} />
            </Center>
            <Menu
                theme="dark"
                mode="inline"
                // defaultSelectedKeys={['chats']}
                // activeKey={location.pathname === '/' ? 'chats' : location.pathname}
                items={[
                    {
                        key: 'chats',
                        icon: <UserOutlined />,
                        label: 'Chats',
                        onClick: () => navigate('/')
                    },
                    // {
                    //     key: '2',
                    //     icon: <VideoCameraOutlined />,
                    //     label: 'nav 2',
                    // },
                    // {
                    //     key: '3',
                    //     icon: <UploadOutlined />,
                    //     label: 'nav 3',
                    // },
                ]}
            />
        </Sider>
        <Layout>
            <Header style={{ padding: 0, background: colorBgContainer }}>
                <Button
                    type="text"
                    icon={collapsed ? <MenuUnfoldOutlined /> : <MenuFoldOutlined />}
                    onClick={() => setCollapsed(!collapsed)}
                    style={{
                        fontSize: '16px',
                        width: 64,
                        height: 64,
                    }}
                />
            </Header>
            <Content
                style={{
                    margin: '24px 16px',
                    padding: 24,
                    minHeight: 280,
                    background: colorBgContainer,
                    borderRadius: borderRadiusLG,
                }}
            >
                <Routes>
                    <Route
                        element={<Home/>}
                        path="/"
                    />
                    <Route
                        element={<Chat/>}
                        path="/chat/:id/:clientId"
                    />
                </Routes>
            </Content>
        </Layout>
    </Layout>
    )
}

const Center = styled.div`
    justify-content: center;
    display: flex;
    align-items: center;
`

const Logo = styled.img`
    width: 40px;
    height: 50px;
`;
