import {createSlice, PayloadAction} from '@reduxjs/toolkit';

const messageSlice = createSlice({
    name: 'messages',
    initialState: {
        messagesChats: {},
    },
    reducers: {
        addMessages(state, action: PayloadAction<any>) {
            const chatId = action.payload[0]?.chatId;
            if (chatId) {
                // @ts-ignore
                state.messagesChats[chatId] = action.payload;
            }
        },
        addMessage(state, action: PayloadAction<any>) {
            const chatId = action.payload.chatId;
            // @ts-ignore
            state.messagesChats[chatId].push(action.payload);
        },
        updateSeenAll(state, action: PayloadAction<any>) {
            const chatId = action.payload.chatId;
            // @ts-ignore
            if (state.messagesChats[chatId]) {
                // @ts-ignore
                state.messagesChats[chatId] = state.messagesChats[chatId].map(
                    (message: {isSeen: boolean; id: any}) => {
                        if (!message.isSeen && message.id === action.payload.userId) {
                            return {...message, isSeen: true};
                        }
                        return message;
                    },
                );
            }
        },
        updateMessage(state, action: PayloadAction<any>) {
            if (action.payload) {
                const {chatId, id} = action.payload;
                // @ts-ignore
                const index = state.messagesChats[chatId].findIndex(
                    (item: {id: any}) => item.id === id,
                );
                if (index) {
                    // @ts-ignore
                    state.messagesChats[chatId][index] = action.payload;
                }
            }
        },
    },
});

export const {addMessages, addMessage, updateSeenAll, updateMessage} =
    messageSlice.actions;
export default messageSlice.reducer;
