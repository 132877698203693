import {createSlice, PayloadAction} from '@reduxjs/toolkit';

const chatsSlice = createSlice({
    name: 'chats',
    initialState: {
        items: {},
    },
    reducers: {
        initChats(state, action: PayloadAction<any>) {
                // @ts-ignore
                state.items = action.payload;
        },
        addChat(state, action: PayloadAction<any>) {
            const {id} = action.payload;
            if (id) {
                // @ts-ignore
                state.items[id] = action.payload;
            }
        },
        removeChats(state, action: PayloadAction<any>) {
            if (action.payload) {
                const {id} = action.payload;
                // @ts-ignore
                delete state.items[id];
            }
        },
    },
});

export const {removeChats, initChats, addChat} =
    chatsSlice.actions;
export default chatsSlice.reducer;
