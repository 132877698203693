import React, {useMemo, useState} from 'react';

export const AuthContext = React.createContext<any>({
    userId: null,
    setUserId: () => {},
});
export const AuthContextProvider: React.FC<any> = ({children}) => {
    const [userId, setUserId] = useState(null);

    const value = useMemo(() => {
        return {userId, setUserId};
    }, [userId]);

    return <AuthContext.Provider value={value}>{children}</AuthContext.Provider>;
};
