import {useContext, useEffect} from 'react';
import {pb} from '../pb';
import {useAppDispatch} from '../../redux/hooks';
import {
    addMessage,
    addMessages,
    updateMessage,
} from '../../redux/messages/messageSlice';

import {AuthContext} from '../../core/AuthContext';

export const getMessages = async (id: string) => {
    try {
        return await pb.collection('messages').getFullList<any>(200, {
            sort: '+created',
            filter: `chatId="${id}"`,
            requestKey: id,
        });
    } catch (e: any) {
        console.log('getMessages', e, e?.originalError);
    }
};

export const Messages = () => {
    const dispatch = useAppDispatch();
    const {setUserId} = useContext(AuthContext);

    useEffect(() => {
        const id = localStorage.getItem("id");
        if (id) {
            setUserId(id);
        }
    }, []);

    useEffect(() => {
         const getData = async () => {
             try {
                 const resp = await pb.collection('chats').getFullList<any>(200, {
                     sort: '+created',
                     expand: 'authorId',
                     requestKey: null,
                 });

                 for (const item of resp) {
                     const items = await getMessages(item.id);
                     dispatch(addMessages(items));
                 }
             } catch (e) {
                 console.log(e)
             }


         }

        getData();

    }, []);


    useEffect(() => {
        pb.collection('messages').subscribe('*', async message => {
            if (message.action === 'create') {
                dispatch(addMessage(message.record));
            }

            if (message.action === 'update') {
                dispatch(updateMessage(message.record));
            }
        });

        return () => {
            pb.collection('messages').unsubscribe('*');
        };
    }, [dispatch]);

    return null;
};
