import React, {useEffect} from "react";
import {useAppDispatch} from "../../redux/hooks";
import {pb} from "../pb";
import {addChat, initChats, removeChats} from "../../redux/chats/chatsSlice";
import { keyBy } from 'lodash'

export const ChatsSubsriber = () => {
    const dispatch = useAppDispatch();

    useEffect(() => {
        const getChatsInit = async () => {
           const chats = await pb.collection('chats').getFullList({ expand: "userId"});
            dispatch(initChats(keyBy(chats, 'id')))
        }

        getChatsInit();
    }, []);


    useEffect(() => {
        pb.collection('chats').subscribe('*', async chat => {
            console.log(chat)
            if (chat.action === 'create') {
                dispatch(addChat(chat.record));
            }


            if (chat.action === 'delete') {
                dispatch(removeChats(chat.record));
            }
        });

        return () => {
            pb.collection('chats').unsubscribe('*');
        };
    }, [dispatch]);

    return null;
};
