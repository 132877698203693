import React, {useEffect, useState} from 'react';
import {
    BrowserRouter,
    Route,
    Routes,
} from "react-router-dom";
import {SignIn} from "../pages/SignIn/SignIn";
import {Template} from "./Template";
import {pb} from "../api/pb";
import {QueryClientProvider, QueryClient} from '@tanstack/react-query';
import {store} from "../redux/store";
import {Provider} from "react-redux";
import {Messages} from "../api/subscribers/Messages";
import {AuthContextProvider} from "./AuthContext";
import {App as AppAntd} from 'antd';
import {ChatsSubsriber} from "../api/subscribers/ChatsSubs";

const queryClient = new QueryClient()

export const App = () => {
    const [auth, setAuth] = useState(pb.authStore.isValid);


    useEffect(() => {
        if (auth) {
            pb.collection('users').authRefresh();
        }

        return pb.authStore.onChange(async (token, model) => {
            if (token) {
                setAuth(true);
            } else {
                setAuth(false);
            }
        });
    }, []);


    return (
        <Provider store={store}>
            <QueryClientProvider client={queryClient}>
                <AuthContextProvider>
                    <AppAntd>
                        <BrowserRouter>
                            <Routes>
                                {!auth ? <Route element={<SignIn/>} path="*"/> :
                                    <>
                                        <Route element={<Template/>} path="/"/>
                                        <Route element={<Template/>} path="*"/>
                                    </>}
                            </Routes>
                        </BrowserRouter>
                        {auth &&  <>
                        <Messages/>
                        <ChatsSubsriber /></>
                        }
                    </AppAntd>
                </AuthContextProvider>
            </QueryClientProvider>
        </Provider>
    );
}
