import React, {useEffect} from "react";
import {Card, Divider, List, Spin, Typography} from "antd";
import {useMutation} from "@tanstack/react-query";
import {pb} from "../../../api/pb";
import styled from "styled-components";

export const InfoClient = ({clientId}: any) => {

    const userMutate = useMutation({
        mutationFn: async () => {
            if (clientId) {
                return await pb.collection('users').getOne(clientId);
            }
        },
    });

    useEffect(() => {
        if (clientId) {
            userMutate.mutateAsync();
        }
    }, []);

    const user = userMutate.data as any;

    return (
        <>
            {userMutate.isPending && <Spin size="large"/>}
            {userMutate.data && <>
                <Container>
                    <Row>
                        <Card bordered={false}>

                            <Typography.Title>{user.name} {user.dataOnboarding.lastName}</Typography.Title>
                            <Divider />
                            <Item>
                                <Typography.Text>Height: {user.dataOnboarding.height} {user.dataOnboarding.heightSize}</Typography.Text>
                            </Item>
                            <Item>
                                <Typography.Text>Weight: {user.dataOnboarding.weight} {user.dataOnboarding.weightSize}</Typography.Text>
                            </Item>
                            <Item>
                                <Typography.Text>Years: {user.dataOnboarding.old}</Typography.Text>
                            </Item>
                            <Item>
                                <Typography.Text>Sex: {user.dataOnboarding.sex}</Typography.Text>
                            </Item>
                           </Card>
                    </Row>
                </Container>
            </>}
        </>
    )
}

const Item = styled.div`
    margin-bottom: 5px;
`

const Container = styled.div`
    display: flex;
    flex-direction: column;
    gap: 12px;
`;

const Row = styled.div``;
