import * as React from 'react';
import {useParams} from "react-router-dom";
import {useContext, useEffect, useMemo, useRef, useState} from "react";
import {useAppDispatch, useAppSelector} from "../../redux/hooks";
import {shallowEqual} from "react-redux";
import {Button, Image, Input, Tag} from 'antd';
import {updateSeenAll} from "../../redux/messages/messageSlice";
import {pb} from "../../api/pb";
import styled from "styled-components";
import {AuthContext} from "../../core/AuthContext";
import dayjs from "dayjs";
import {useMutation} from "@tanstack/react-query";
import {InfoClient} from "./components/InfoClient";

export const Chat = () => {
    const {id, clientId} = useParams<any>();
    const ref = useRef<any>(null);
    const {userId} = useContext(AuthContext);

    const dispatch = useAppDispatch();
    const [value, setValue] = useState('');

    const messages = useAppSelector(({messages}) => {
        // @ts-ignore
        const chat = messages.messagesChats[id] as any[];
        if (chat) {
            return chat;
        }

        return [];
    }, shallowEqual);

    useEffect(() => {
        const updateSeenMessages = async () => {
            try {
                dispatch(updateSeenAll({id, userId}));
                messages.forEach(message => {
                    if (!message.isSeen && message.userId !== userId) {
                        pb.collection('messages').update(
                            message.id,
                            {isSeen: true},
                            {requestKey: message.id},
                        );
                    }
                });
            } catch (e: any) {
                console.log('updateSeenMessages', e?.originalError);
            }
        };

        updateSeenMessages();
    }, [messages]);

    const messagesMemo = useMemo(() => {
        return messages
            .map(item => ({
                ...item,
                image: item.image ? pb.files.getUrl(item, item.image) : undefined,
                type: item.image ? 'image' : 'text',
                seen: item.isSeen,
                isMy: item.userId === userId,
            }));
    }, [messages]);

    const sendMessage = () => {
        if (value.length >= 1) {
            try {
                setValue('');
                pb.collection('messages').create({
                    chatId: id,
                    text: value,
                    userId: userId,
                    isSeen: false,
                });

            } catch (e: any) {
                console.log('sendMessage', e?.originalError);
            }
        }
    };

    // const onSendImage = useCallback(async (assets: any[]) => {
    //     try {
    //         if (assets[0]) {
    //             // const data = new FormData();
    //             // data.append('image', {
    //             //     uri: assets[0].uri,
    //             //     type: assets[0].type,
    //             //     name: assets[0].fileName,
    //             // });
    //             //
    //             // data.append('chatId', id || '');
    //             // data.append('userId', userId);
    //             // data.append('isSeen', 'false');
    //             //
    //             // await pb.collection('messages').create(data);
    //         }
    //     } catch (e: any) {
    //         console.log('onSendImage', e, e?.originalError);
    //     }
    // }, []);

    useEffect(() => {
        ref.current?.scrollIntoView();
    }, []);

    useEffect(() => {
        if (messagesMemo.length) {
            ref.current?.scrollIntoView({behavior: "smooth"});
        }
    }, [messagesMemo.length]);

    const actionsMutate = useMutation({
        mutationFn: async ({status, id}: any) => {
            try {
                await pb.collection('messages').update(id, {status: status})
                ref.current?.scrollIntoView();
            } catch (e) {
                console.log(e);
            }
        }
    })


    return (
        <Container>
            <Content>
                <Scroll>
                    <List>
                        {messagesMemo.map((item) => {
                            return (
                                <Msg key={item.id} $isMy={item.isMy}>
                                    <ContentMsg $isMy={item.isMy}>
                                        {item.type === 'image' ?
                                            <Image width={150} height={150} src={item.image}/> : item.text}
                                        <Time>{dayjs(item.created).format('h:mm A')}</Time>

                                        {item?.status && <Status>
                                            <Tag color={item?.status === 'approved' ? "green" : 'red'}>
                                                {item.status}
                                            </Tag>
                                        </Status>}
                                    </ContentMsg>
                                    {!item.isMy && item.type === 'image' && !item?.status && <Actions>
                                        <Button type='primary'
                                                disabled={actionsMutate.isPending}
                                                onClick={() => actionsMutate.mutateAsync({
                                                    id: item.id,
                                                    status: 'approved'
                                                })}>
                                            Approve
                                        </Button>
                                        <Button danger disabled={actionsMutate.isPending}
                                                onClick={() => actionsMutate.mutateAsync({
                                                    id: item.id,
                                                    status: 'notapproved'
                                                })}>
                                            Not approved
                                        </Button>
                                    </Actions>}
                                </Msg>
                            )
                        })}
                    </List>
                    <div ref={ref}/>
                </Scroll>
                <Footer>
                    <Input.TextArea
                        value={value}
                        onChange={(e) => setValue(e.target.value)}
                        placeholder="Send message"
                        autoSize={{minRows: 3, maxRows: 5}}
                    />
                    <Button type='primary' disabled={value.length === 0} size='large' onClick={sendMessage}>
                        Send
                    </Button>
                </Footer>
            </Content>
            <Right>
                <InfoClient clientId={clientId}/>
            </Right>
        </Container>
    )
}

const Status = styled.div`
    display: flex;
    align-items: center;
    justify-content: center;
    margin-top: 5px;
    text-transform: capitalize;
`;

const Time = styled.div`
    text-align: right;
    font-size: 11px;
    line-height: 0;
    margin-top: 6px;
`;

const Container = styled.div`
    width: 100%;
    display: flex;
    flex-direction: row;
`

const Right = styled.div`
    width: 30%;
    min-width: 300px;
`

const Content = styled.div`
    width: 100%;

`

const Actions = styled.div`
    display: flex;
    flex-direction: column;
    gap: 2px;
    justify-content: flex-end;
`

const ContentMsg = styled.div<{ $isMy?: boolean }>`
    background: ${({$isMy}) => $isMy ? '#CCF8CF' : '#F5F5F5'};
    padding: 10px;
    border-radius: 15px;
    display: inline-block;
    overflow: hidden;
    max-width: 80%;
    line-height: 25px;
`

const Msg = styled.div<{ $isMy?: boolean }>`
    display: flex;
    justify-content: ${({$isMy}) => $isMy ? 'flex-end' : 'flex-start'};
`

const Scroll = styled.div`
    overflow-y: scroll;
    max-width: 1000px;
    height: 400px;
    border: 1px solid rgba(5, 5, 5, 0.06);
    border-radius: 8px;
    padding: 15px 15px 10px 15px;
    margin-bottom: 25px;
`
const List = styled.div`
    display: flex;
    flex-direction: column;
    gap: 15px;
    justify-content: end;
`

const Footer = styled.div`
    display: flex;
    gap: 15px;
    max-width: 1000px;
`;


