import {configureStore} from '@reduxjs/toolkit';
import messagesReducer from './messages/messageSlice';
import chatsReducer from './chats/chatsSlice';

export const store = configureStore({
    reducer: {
        messages: messagesReducer,
        chats: chatsReducer
    },
});

export type AppDispatch = typeof store.dispatch;
export type RootState = ReturnType<typeof store.getState>;
