import * as React from 'react';
import {pb} from "../../api/pb";
import {useContext, useState} from "react";
import {Button, Input, notification, Typography} from 'antd';

import {useMutation} from "@tanstack/react-query";
import styled from "styled-components";
import {HomeFilled} from "@ant-design/icons";
import {AuthContext} from "../../core/AuthContext";


export const SignIn = () => {
    const [api, contextHolder] = notification.useNotification();
    const {setUserId} = useContext(AuthContext);

    const [email, setEmail] = useState<string>('');
    const [password, setPassword] = useState<string>('');

    const handleSubmitMutate = useMutation({
        mutationFn: async (event: any) => {
            event.preventDefault();
            try {
                const record = await pb.collection('users').authWithPassword(email, password);
                localStorage.setItem("id", record.record.id);
                setUserId(record.record.id);
            } catch (e) {
                openNotificationWithIcon('Invalid Credentials');
            }

        }
    });


    const openNotificationWithIcon = (name: string) => {
        api.error({
            message: name,
            description: 'Contact your administrator.',
        });
    };

    return (
        <Page>

            <Container>

               <Row>
                   <HomeFilled  style={{fontSize: 30}} />
                   <Typography.Title style={{lineHeight: 0, margin: 0}}>SnapDiet</Typography.Title>
               </Row>

                <Input
                required
                placeholder="Username or Email Address"
                name="email"
                autoComplete="email"
                autoFocus
                onChange={(e: any) => setEmail(e.target.value)}
            />
                <Input
                    required
                    placeholder="Password"
                    name="password"
                    type="password"
                    autoComplete="current-password"
                    onChange={(e: any) => setPassword(e.target.value)}
                />
                <Button
                    type="primary"
                    onClick={handleSubmitMutate.mutateAsync}
                    disabled={handleSubmitMutate.isPending}
                >
                    Sign In
                </Button>
            </Container>
            {contextHolder}
        </Page>
    );
}

const  Row = styled.div`
    display: flex;
    gap: 15px;
    justify-content: center;
    align-items: center;
    margin-bottom: 10px;
`

const Page = styled.div`
   
`;

const Container = styled.div`
    max-width: 350px;
    padding: 60px 40px;
    gap: 20px;
    display: flex;
    flex-direction: column;
    width: 100%;
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    border: 1px solid rgba(5, 5, 5, 0.06);
    border-radius: 8px;
`;
